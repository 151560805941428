// //------------------------------------Valsai Baleto Pamokoms--------------------------------------------------//

// //card1
// import video1 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.1/1.mp4';

// //card2
// import video2 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.2/2.mp4';

// //card3
// import video3 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.3/3.mp4';

// //card4
// import video4 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.4/4.mp4';

// //card5
// import video5 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.5/5.mp4';

// //card6
// import video6 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.6/6.mp4';

// //card7
// import video7 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.7/7.mp4';

// //card8
// import video8 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.8/8.mp4';

// //card9
// import video9 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.9/9.mp4';

// //card10
// import video10 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.10/10.mp4';

// //card11
// import video11 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.11/11.mp4';

// //card12
// import video12 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.12/12.mp4';

// //card13
// import video13 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.13/13.mp4';

// //card14
// import video14 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.14/14.mp4';

// //card15
// import video15 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.15/15.mp4';

// //card16
// import video16 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.16/16.mp4';

// //card17
// import video17 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.17/17.mp4';

// //card18
// import video18 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.18/18.mp4';

// //card19
// import video19 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.19/19.mp4';

// //card20
// import video20 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.20/20.mp4';

// //card21
// import video21 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.21/21.mp4';

// //card22
// import video22 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.22/22.mp4';

// //card23
// import video23 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.23/23.mp4';

// //card24
// import video24 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.24/24.mp4';

// //card25
// import video25 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.25/25.mp4';

// //card26
// import video26 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.26/26.mp4';

// //card27
// import video27 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.27/27.mp4';

// //card28
// import video28 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.28/28.mp4';

// //card29
// import video29 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.29/29.mp4';

// //card30
// import video30 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.30/30.mp4';

// //card31
// import video31 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.31/31.mp4';

// //card32
// import video32 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.32/32.mp4';

// //card33
// import video33 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.33/33.mp4';

// //card34
// import video34 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.34/34.mp4';

// //card35
// import video35 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.35/35.mp4';

// //card36
// import video36 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.36/36.mp4';

// //card37
// import video37 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.37/37.mp4';

// //card38
// import video38 from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.38/38.mp4';

// picture

// import video1 from '../../Albumbs/mariui antras rinkinys cirkas atvaziavo/1. Cirko marsas/video1.mp4';
// import testPictureRu from '../../Albumbs/valsai-baleto-pamokos/Valsas Nr.1/A4 virselis - rišimui spirale RU 2021 03 17_1 - derinimui (1).jpg';
// import testPictureLt from '../../Albumbs/valsai-baleto-pamokos/A4+3mm virselis - rišimui spirale LT_1.jpg';
// import testPictureEng from '../../Albumbs/valsai-baleto-pamokos/A4+3mm virselis - rišimui spirale EN - x-WEB_Page_1.jpg';

let testPictureRu =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2Fimg%2FA4%20virselis%20-%20ri%C5%A1imui%20spirale%20RU%202021%2003%2017_1%20-%20derinimui%20(1).jpg?alt=media&token=f59ed19d-4ce4-41ad-bdab-5b0ecbec1d43';
let testPictureLt =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2Fimg%2FA4%2B3mm%20virselis%20-%20ri%C5%A1imui%20spirale%20LT_1.jpg?alt=media&token=d8cd69d0-378a-47f7-ad37-500ffea69b21';
let testPictureEng =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2Fimg%2FA4%2B3mm%20virselis%20-%20ri%C5%A1imui%20spirale%20EN%20-%20x-WEB_Page_1.jpg?alt=media&token=19cd2c04-d703-428b-8fc8-d12606f01eb7';

let video1 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F1.mp4?alt=media&token=22d5e6dd-36cd-4ce2-ae1e-0701fe61ea78';
let video2 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F2.mp4?alt=media&token=d7c32bbc-4770-468f-ab34-c39ece217f73';
let video3 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F3.mp4?alt=media&token=66bf1f75-f010-442e-82ff-c417b0c9a9a6';
let video4 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F4.mp4?alt=media&token=cbb81080-871a-4f1f-a6bd-b4aa110e1253';
let video5 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F5.mp4?alt=media&token=4635df06-faf5-4b63-a63b-0116e303c04f';
let video6 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F6.mp4?alt=media&token=a7cfdbc2-44f3-4472-a4bf-8c2fd131249e';
let video7 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F7.mp4?alt=media&token=c6857b15-8c2a-409c-90a8-bbbfe861aea1';
let video8 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F8.mp4?alt=media&token=bdc0e6f0-f06b-4b4b-8ee7-5adc356b9331';
let video9 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F9.mp4?alt=media&token=1d03cda9-0c3f-4ec8-b4a4-0c903bc7a574';
let video10 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F10.mp4?alt=media&token=56d21939-d104-4ea6-91da-4980046ab7e3';
let video11 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F11.mp4?alt=media&token=43a789b4-1cdb-4b16-9d5b-a15bf53d0398';
let video12 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F12.mp4?alt=media&token=77ec4274-ae0b-4765-858f-c632b88cb953';
let video13 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F13.mp4?alt=media&token=c2d0ea70-f63b-4ebc-b336-44e3a503b8e5';
let video14 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F14.mp4?alt=media&token=e51c06fb-37d4-43c1-ba95-c64ca376ea1c';
let video15 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F15.mp4?alt=media&token=84953d23-dc83-402f-a442-356301d10f95';
let video16 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F16.mp4?alt=media&token=338d799c-6aab-4121-bb6f-1f30d881f3b1';
let video17 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F17.mp4?alt=media&token=40b56ec9-7d50-4241-a05b-18395da142a8';
let video18 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F18.mp4?alt=media&token=e4308417-9b9d-4f69-9357-3e066ec921c6';
let video19 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F19.mp4?alt=media&token=6b292bb5-4a56-4ab7-be4a-236da70cb888';
let video20 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F20.mp4?alt=media&token=a171c982-5ba9-4cdb-920c-23f5c8db35f7';
let video21 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F21.mp4?alt=media&token=b26366c8-7096-44d7-a358-4d54b3e3a29c';
let video22 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F22.mp4?alt=media&token=68da1831-a014-4a23-8d07-05999fe701c6';
let video23 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F23.mp4?alt=media&token=6eaae86c-f96b-4fac-89db-322822d20a88';
let video24 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F24.mp4?alt=media&token=ac27fb1b-fd6c-4fb3-80b6-c26213baf81a';
let video25 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F25.mp4?alt=media&token=de094204-c18e-4971-8829-e35d51662828';
let video26 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F26.mp4?alt=media&token=9ef0dd48-b0c4-4c31-a7fc-ec073fb059b4';
let video27 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F27.mp4?alt=media&token=30d18143-2236-462e-ada2-00a5c26403f4';
let video28 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F28.mp4?alt=media&token=dd19195f-708e-4ec5-bae3-2ac7e7a78b34';
let video29 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F29.mp4?alt=media&token=f5b9c510-15dd-4f95-a7ac-9a5cce18f876';
let video30 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F30.mp4?alt=media&token=d195f5c6-5af9-40f1-b02a-12d30846bf18';
let video31 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F31.mp4?alt=media&token=65542975-4c27-461f-af89-02f5612c28a8';
let video32 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F32.mp4?alt=media&token=2f425db3-dde6-48c1-9b56-bb37fc766a9a';
let video33 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F33.mp4?alt=media&token=8f31e8d4-a1b7-4fe3-813d-c7a88def577e';
let video34 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F34.mp4?alt=media&token=fd192929-4373-4bb1-a75f-d373a593b8bc';
let video35 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F35.mp4?alt=media&token=6d1b37ab-5b53-4ca0-9bdf-76b20bbcfcde';
let video36 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F36.mp4?alt=media&token=995ce95a-685c-458c-86c2-807d525c243e';
let video37 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F37.mp4?alt=media&token=f04582f1-4cf8-4195-aca7-c1de43ed018b';
let video38 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/valsai%2F38.mp4?alt=media&token=7375a231-1f3a-407e-8284-c543375a7274';

export const valsaiLt = [
  {
    id: 1,
    noteID: 55,
    albumprice: 14,
    songprice: 1,
    video: video1,
    song: 'Valsas ',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
    holealbumsold: false,
  },
  {
    id: 2,
    noteID: 56,
    albumprice: 14,
    songprice: 1.5,
    video: video2,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 3,
    noteID: 57,
    albumprice: 14,
    songprice: 1.5,
    video: video3,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 4,
    noteID: 58,
    albumprice: 14,
    songprice: 1,
    video: video4,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 5,
    noteID: 59,
    albumprice: 14,
    songprice: 1,
    video: video5,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 6,
    noteID: 60,
    albumprice: 14,
    songprice: 1.5,
    video: video6,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 7,
    noteID: 61,
    albumprice: 14,
    songprice: 1.5,
    video: video7,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 8,
    noteID: 62,
    albumprice: 14,
    songprice: 1,
    video: video8,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 9,
    noteID: 63,
    albumprice: 14,
    songprice: 1,
    video: video9,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 10,
    noteID: 64,
    albumprice: 14,
    songprice: 1,
    video: video10,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 11,
    noteID: 65,
    albumprice: 14,
    songprice: 1.5,
    video: video11,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 12,
    noteID: 66,
    albumprice: 14,
    songprice: 1,
    video: video12,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 13,
    noteID: 67,
    albumprice: 14,
    songprice: 1,
    video: video13,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 14,
    noteID: 68,
    albumprice: 14,
    songprice: 1,
    video: video14,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 15,
    noteID: 69,
    albumprice: 14,
    songprice: 1,
    video: video15,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 16,
    noteID: 70,
    albumprice: 14,
    songprice: 1.5,
    video: video16,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 17,
    noteID: 71,
    albumprice: 14,
    songprice: 1,
    video: video17,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 18,
    noteID: 72,
    albumprice: 14,
    songprice: 1,
    video: video18,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 19,
    noteID: 73,
    albumprice: 14,
    songprice: 1,
    video: video19,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 20,
    noteID: 74,
    albumprice: 14,
    songprice: 1,
    video: video20,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },

  {
    id: 21,
    noteID: 75,
    albumprice: 14,
    songprice: 1,
    video: video21,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 22,
    noteID: 76,
    albumprice: 14,
    songprice: 1.5,
    video: video22,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 23,
    noteID: 77,
    albumprice: 14,
    songprice: 1,
    video: video23,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 24,
    noteID: 78,
    albumprice: 14,
    songprice: 1,
    video: video24,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 25,
    noteID: 79,
    albumprice: 14,
    songprice: 1,
    video: video25,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 26,
    noteID: 80,
    albumprice: 14,
    songprice: 1.5,
    video: video26,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 27,
    noteID: 81,
    albumprice: 14,
    songprice: 1.5,
    video: video27,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },

  {
    id: 28,
    noteID: 82,
    albumprice: 14,
    songprice: 1.5,
    video: video28,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 29,
    noteID: 83,
    albumprice: 14,
    songprice: 1.5,
    video: video29,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 30,
    noteID: 84,
    albumprice: 14,
    songprice: 1,
    video: video30,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 31,
    noteID: 85,
    albumprice: 14,
    songprice: 1,
    video: video31,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },

  {
    id: 32,
    noteID: 86,
    albumprice: 14,
    songprice: 1,
    video: video32,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 33,
    noteID: 87,
    albumprice: 14,
    songprice: 1.5,
    video: video33,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 34,
    noteID: 88,
    albumprice: 14,
    songprice: 1.5,
    video: video34,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },

  {
    id: 35,
    noteID: 89,
    albumprice: 14,
    songprice: 1.5,
    video: video35,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 36,
    noteID: 90,
    albumprice: 14,
    songprice: 1,
    video: video36,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 37,
    noteID: 91,
    albumprice: 14,
    songprice: 1,
    video: video37,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
  {
    id: 38,
    noteID: 92,
    albumprice: 14,
    songprice: 1.5,
    video: video38,
    song: 'Valsas',
    picture: testPictureLt,
    album: 'Valsai baleto pamokoms',
  },
];

// Valsai ENG --------------------------------------------------------------------
export const valsaiEng = [
  {
    id: 1,
    noteID: 93,
    albumprice: 14,
    songprice: 1,
    video: video1,
    song: 'Vals ',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
    holealbumsold: false,
  },
  {
    id: 2,
    noteID: 94,
    albumprice: 14,
    songprice: 1.5,
    video: video2,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 3,
    noteID: 95,
    albumprice: 14,
    songprice: 1.5,
    video: video3,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 4,
    noteID: 96,
    albumprice: 14,
    songprice: 1,
    video: video4,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 5,
    noteID: 97,
    albumprice: 14,
    songprice: 1,
    video: video5,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 6,
    noteID: 98,
    albumprice: 14,
    songprice: 1.5,
    video: video6,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 7,
    noteID: 99,
    albumprice: 14,
    songprice: 1.5,
    video: video7,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 8,
    noteID: 100,
    albumprice: 14,
    songprice: 1,
    video: video8,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 9,
    noteID: 101,
    albumprice: 14,
    songprice: 1,
    video: video9,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 10,
    noteID: 102,
    albumprice: 14,
    songprice: 1,
    video: video10,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 11,
    noteID: 103,
    albumprice: 14,
    songprice: 1.5,
    video: video11,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 12,
    noteID: 104,
    albumprice: 14,
    songprice: 1,
    video: video12,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 13,
    noteID: 105,
    albumprice: 14,
    songprice: 1,
    video: video13,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 14,
    noteID: 106,
    albumprice: 14,
    songprice: 1,
    video: video14,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 15,
    noteID: 107,
    albumprice: 14,
    songprice: 1,
    video: video15,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 16,
    noteID: 108,
    albumprice: 14,
    songprice: 1.5,
    video: video16,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 17,
    noteID: 109,
    albumprice: 14,
    songprice: 1,
    video: video17,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 18,
    noteID: 110,
    albumprice: 14,
    songprice: 1,
    video: video18,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 19,
    noteID: 111,
    albumprice: 14,
    songprice: 1,
    video: video19,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 20,
    noteID: 112,
    albumprice: 14,
    songprice: 1,
    video: video20,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },

  {
    id: 21,
    noteID: 113,
    albumprice: 14,
    songprice: 1,
    video: video21,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 22,
    noteID: 114,
    albumprice: 14,
    songprice: 1.5,
    video: video22,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 23,
    noteID: 115,
    albumprice: 14,
    songprice: 1,
    video: video23,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 24,
    noteID: 116,
    albumprice: 14,
    songprice: 1,
    video: video24,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 25,
    noteID: 117,
    albumprice: 14,
    songprice: 1,
    video: video25,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 26,
    noteID: 118,
    albumprice: 14,
    songprice: 1.5,
    video: video26,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 27,
    noteID: 119,
    albumprice: 14,
    songprice: 1.5,
    video: video27,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },

  {
    id: 28,
    noteID: 120,
    albumprice: 14,
    songprice: 1.5,
    video: video28,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 29,
    noteID: 121,
    albumprice: 14,
    songprice: 1.5,
    video: video29,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 30,
    noteID: 122,
    albumprice: 14,
    songprice: 1,
    video: video30,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 31,
    noteID: 123,
    albumprice: 14,
    songprice: 1,
    video: video31,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },

  {
    id: 32,
    noteID: 124,
    albumprice: 14,
    songprice: 1,
    video: video32,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 33,
    noteID: 125,
    albumprice: 14,
    songprice: 1.5,
    video: video33,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 34,
    noteID: 126,
    albumprice: 14,
    songprice: 1,
    video: video34,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },

  {
    id: 35,
    noteID: 127,
    albumprice: 14,
    songprice: 1.5,
    video: video35,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 36,
    noteID: 128,
    albumprice: 14,
    songprice: 1,
    video: video36,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 37,
    noteID: 129,
    albumprice: 14,
    songprice: 1,
    video: video37,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
  {
    id: 38,
    noteID: 130,
    albumprice: 14,
    songprice: 1.5,
    video: video38,
    song: 'Vals',
    picture: testPictureEng,
    album: 'Waltzes for ballet lessons',
  },
];
// ValsaiRu -----------------------------------------------------------------------
export const valsaiRu = [
  {
    id: 1,
    noteID: 131,
    albumprice: 14,
    songprice: 1,
    video: video1,
    song: 'Вальс ',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
    holealbumsold: false,
  },
  {
    id: 2,
    noteID: 132,
    albumprice: 14,
    songprice: 1.5,
    video: video2,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 3,
    noteID: 133,
    albumprice: 14,
    songprice: 1.5,
    video: video3,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 4,
    noteID: 134,
    albumprice: 14,
    songprice: 1,
    video: video4,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 5,
    noteID: 135,
    albumprice: 14,
    songprice: 1,
    video: video5,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 6,
    noteID: 136,
    albumprice: 14,
    songprice: 1.5,
    video: video6,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 7,
    noteID: 137,
    albumprice: 14,
    songprice: 1.5,
    video: video7,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 8,
    noteID: 138,
    albumprice: 14,
    songprice: 1,
    video: video8,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 9,
    noteID: 139,
    albumprice: 14,
    songprice: 1,
    video: video9,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 10,
    noteID: 140,
    albumprice: 14,
    songprice: 1,
    video: video10,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 11,
    noteID: 141,
    albumprice: 14,
    songprice: 1.5,
    video: video11,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 12,
    noteID: 142,
    albumprice: 14,
    songprice: 1,
    video: video12,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 13,
    noteID: 143,
    albumprice: 14,
    songprice: 1,
    video: video13,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 14,
    noteID: 144,
    albumprice: 14,
    songprice: 1,
    video: video14,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 15,
    noteID: 145,
    albumprice: 14,
    songprice: 1,
    video: video15,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 16,
    noteID: 146,
    albumprice: 14,
    songprice: 1.5,
    video: video16,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 17,
    noteID: 147,
    albumprice: 14,
    songprice: 1,
    video: video17,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 18,
    noteID: 148,
    albumprice: 14,
    songprice: 1,
    video: video18,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 19,
    noteID: 149,
    albumprice: 14,
    songprice: 1,
    video: video19,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 20,
    noteID: 150,
    albumprice: 14,
    songprice: 1,
    video: video20,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },

  {
    id: 21,
    noteID: 151,
    albumprice: 14,
    songprice: 1,
    video: video21,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 22,
    noteID: 152,
    albumprice: 14,
    songprice: 1.5,
    video: video22,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 23,
    noteID: 153,
    albumprice: 14,
    songprice: 1,
    video: video23,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 24,
    noteID: 154,
    albumprice: 14,
    songprice: 1,
    video: video24,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 25,
    noteID: 155,
    albumprice: 14,
    songprice: 1,
    video: video25,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 26,
    noteID: 156,
    albumprice: 14,
    songprice: 1.5,
    video: video26,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 27,
    noteID: 157,
    albumprice: 14,
    songprice: 1.5,
    video: video27,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },

  {
    id: 28,
    noteID: 158,
    albumprice: 14,
    songprice: 1.5,
    video: video28,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 29,
    noteID: 159,
    albumprice: 14,
    songprice: 1.5,
    video: video29,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 30,
    noteID: 160,
    albumprice: 14,
    songprice: 1,
    video: video30,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 31,
    noteID: 161,
    albumprice: 14,
    songprice: 1,
    video: video31,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },

  {
    id: 32,
    noteID: 162,
    albumprice: 14,
    songprice: 1,
    video: video32,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 33,
    noteID: 163,
    albumprice: 14,
    songprice: 1.5,
    video: video33,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 34,
    noteID: 164,
    albumprice: 14,
    songprice: 1,
    video: video34,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 35,
    noteID: 165,
    albumprice: 14,
    songprice: 1.5,
    video: video35,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 36,
    noteID: 166,
    albumprice: 14,
    songprice: 1,
    video: video36,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 37,
    noteID: 167,
    albumprice: 14,
    songprice: 1,
    video: video37,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
  {
    id: 38,
    noteID: 168,
    albumprice: 14,
    songprice: 1.5,
    video: video38,
    song: 'Вальс',
    picture: testPictureRu,
    album: 'Вальсы для уроков балета',
  },
];
