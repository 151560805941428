// import video1 from '../../Albumbs/muzikinė-pasaka/1.Pasakų-šalis/1.mp4';

// import video2 from '../../Albumbs/muzikinė-pasaka/2.Vilkas/2.mp4'; //FIXME:atskirti kepure nuo vilko
// import video3 from "../../Albumbs/muzikinė-pasaka/3.Raudonkepuraite/3.mp4"; nera //FIXME:atskirti kepure nuo vilko

let pictureLtEng =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2Fimg%2FMuzikine%20pasaka%20LT-EN%20-%20web-pages-1-1.jpg?alt=media&token=ade27c57-46b6-499a-b11c-bf3b8461df2e';
let pictureRu =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2Fimg%2FMuzikine%20pasaka%20RU-EN%20-%20web-pages-1-1.jpg?alt=media&token=bcbaa31f-a667-4de6-bf5d-fe6950954d58';
// FIXME: truksta 2 dainu

let video1 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F1.mp4?alt=media&token=5d5eff22-3010-4289-af0a-b60a6582aeb5';
let video2 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F2.mp4?alt=media&token=ddde4152-7890-486c-b412-9fe4d557ff6b';
let video3 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F3.mp4?alt=media&token=edde3347-32bb-45c2-b397-ae811966fada';
let video4 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F4.mp4?alt=media&token=d53fde8e-d71d-4e53-a06e-786524f4f100';
let video5 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F5.mp4?alt=media&token=83438d41-e428-42cb-8962-ed2543b1420a';
let video6 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F6.mp4?alt=media&token=af1f32d8-151c-4db4-8df8-1729365a8481';
let video7 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F7.mp4?alt=media&token=ade3e126-4788-4f30-a94b-79bb2d659045';
let video8 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F8.mp4?alt=media&token=e5fac55d-2992-4ed9-b815-b2444eabc6ac';
let video9 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F9.mp4?alt=media&token=3e2f8451-07ab-463d-89f1-5d01eb58675d';
let video10 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F10.mp4?alt=media&token=afb600d6-dbcb-4682-971c-a49fcc9a9259';
let video11 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F11.mp4?alt=media&token=9714e136-e55b-46e6-9da6-3364f5654e98';
let video12 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F12.mp4?alt=media&token=874e126c-1bcd-42c6-a7c7-c76d4e024c45';
let video13 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F13.mp4?alt=media&token=33d46a50-8d89-4886-a87f-61150fcc7a73';
let video14 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F14.mp4?alt=media&token=ae4caa67-305a-40e3-97d9-8ddbae310828';
let video15 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F15.mp4?alt=media&token=b4d2edef-a932-4be3-b646-99a7902661b1';
let video16 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F16.mp4?alt=media&token=b9999c3a-ca66-43ae-b91a-1b4053d5ba19';
let video17 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F17.mp4?alt=media&token=892296fe-6988-4ec3-bf3e-1afb3c659840';
let video18 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F18.mp4?alt=media&token=1e77d338-897f-4aca-84c8-fc692baf6156';
let video19 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F19.mp4?alt=media&token=ffdbe92b-c792-4c80-903f-2fa6c2024e55';
let video20 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F20.mp4?alt=media&token=7a55b0f8-e4bf-4efe-9f57-ea3a42a4f8c0';
let video21 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F21.mp4?alt=media&token=9ffe4041-5a53-4d13-b0dc-3165f3f54a3d';
let video22 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F22.mp4?alt=media&token=1b8750c8-dea3-4b10-812f-e57d94ee5583';
let video23 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F23.mp4?alt=media&token=d7802a65-a3ca-4278-b9b2-4bc3743fc3f0';
let video24 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F24.mp4?alt=media&token=c74e626e-a31e-4c60-8fe4-3418657578cf';
let video25 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F25.mp4?alt=media&token=022de627-e397-4865-8318-cd6a6189719d';
let video26 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F26.mp4?alt=media&token=0ee4ab17-2fe5-43ff-8b59-1ab8bb388c3d';
let video27 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F27.mp4?alt=media&token=947f5874-13fa-4ac9-91be-66e1a524fd42';
let video28 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F28.mp4?alt=media&token=4f7e4a23-a758-4a0a-a675-cb59dfc0861d';
let video29 =
  'https://firebasestorage.googleapis.com/v0/b/pianonotes-a108c.appspot.com/o/muzikinepasaka%2F29.mp4?alt=media&token=dd2e2088-5026-4b40-91e8-deb51e6a1d23';
export const muzikinePasakaLt = [
  {
    id: 1,
    noteID: 169,
    songprice: 1.5,
    buy: false,
    video: video1,
    song: 'Pasakų Šalis',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
    holealbumsold: false,
  },
  {
    id: 2,
    noteID: 170,
    songprice: 1,
    buy: false,
    video: video2,
    song: 'Vilkas',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 3,
    noteID: 171,
    songprice: 1,
    buy: false,
    video: video3,
    song: 'Raudonkepuraitė',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 4,
    noteID: 172,
    songprice: 1.5,
    buy: false,
    video: video4,
    song: 'Meška',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 5,
    noteID: 173,
    songprice: 2,
    buy: false,
    video: video5,
    song: 'Bitės',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 6,
    noteID: 174,
    songprice: 1.5,
    buy: false,
    video: video6,
    song: 'Meškiukas - Pūkuotukas',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 7,
    noteID: 175,
    songprice: 1,
    buy: false,
    video: video7,
    song: 'Liudnas Asiliukas',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 8,
    noteID: 176,
    songprice: 1,
    buy: false,
    video: video8,
    song: 'Išmintinga Pelėda',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 9,
    noteID: 177,
    songprice: 1,
    buy: false,
    video: video9,
    song: 'Pasiklyde Vaikai',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 10,
    noteID: 178,
    songprice: 1.5,
    buy: false,
    video: video10,
    song: 'Ragana',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 11,
    noteID: 179,
    songprice: 1.5,
    buy: false,
    video: video11,
    song: 'Nykštukai',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 12,
    noteID: 180,
    songprice: 1.5,
    buy: false,
    video: video12,
    song: 'Fėja',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 13,
    noteID: 181,
    songprice: 2,
    buy: false,
    video: video13,
    song: 'Burtininkas',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 14,
    noteID: 182,
    songprice: 1.5,
    buy: false,
    video: video14,
    song: 'Varna',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 15,
    noteID: 183,
    songprice: 2,
    buy: false,
    video: video15,
    song: 'Lapė',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 16,
    noteID: 184,
    songprice: 1.5,
    buy: false,
    video: video16,
    song: 'Vikšras - Peteliškė',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 17,
    noteID: 185,
    songprice: 2,
    buy: false,
    video: video17,
    song: 'Vištos Ir Gaidžiai',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 18,
    noteID: 186,
    songprice: 1.5,
    buy: false,
    video: video18,
    song: 'Katė',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 19,
    noteID: 187,
    songprice: 2,
    buy: false,
    video: video19,
    song: 'Žuvėdra',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 20,
    noteID: 188,
    songprice: 1.5,
    buy: false,
    video: video20,
    song: 'Bangos',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 21,
    noteID: 189,
    songprice: 1.5,
    buy: false,
    video: video21,
    song: 'Gulbė',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 22,
    noteID: 190,
    songprice: 2,
    buy: false,
    video: video22,
    song: 'Žirgai',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 23,
    noteID: 191,
    songprice: 2,
    buy: false,
    video: video23,
    song: 'Gėlės',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 24,
    noteID: 192,
    songprice: 2,
    buy: false,
    video: video24,
    song: 'Oro Balionėlis',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 25,
    noteID: 193,
    songprice: 2,
    buy: false,
    video: video25,
    song: 'Pavasaris',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 26,
    noteID: 194,
    songprice: 1.5,
    buy: false,
    video: video26,
    song: 'Vasara Prie Jūros',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 27,
    noteID: 195,
    songprice: 1.5,
    buy: false,
    video: video27,
    song: 'Ruduo Vėjas Ir Lapai',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 28,
    noteID: 196,
    songprice: 1.5,
    buy: false,
    video: video28,
    song: 'Žiema Nuo Kalniuko',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
  {
    id: 29,
    noteID: 197,
    songprice: 2.5,
    buy: false,
    video: video29,
    song: 'Bangos II',
    picture: pictureLtEng,
    album: 'Muzikinė Pasaka',
    albumprice: 15,
  },
];
export const muzikinePasakaEng = [
  {
    id: 1,
    noteID: 198,
    songprice: 1.5,
    buy: false,
    video: video1,
    song: 'Fabulous Country',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
    holealbumsold: false,
  },
  {
    id: 2,
    noteID: 199,
    songprice: 1,
    buy: false,
    video: video2,
    song: 'Wolf',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 3,
    noteID: 200,
    songprice: 1,
    buy: false,
    video: video3,
    song: 'Little Red Riding Hood',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 4,
    noteID: 201,
    songprice: 1.5,
    buy: false,
    video: video4,
    song: 'Bear',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 5,
    noteID: 202,
    songprice: 2,
    buy: false,
    video: video5,
    song: 'Bees',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 6,
    noteID: 203,
    songprice: 1.5,
    buy: false,
    video: video6,
    song: 'Teddy Bear - Pooh',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 7,
    noteID: 204,
    songprice: 1,
    buy: false,
    video: video7,
    song: 'Sad Donkey',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 8,
    noteID: 205,
    songprice: 1,
    buy: false,
    video: video8,
    song: 'The Wise Owl',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 9,
    noteID: 206,
    songprice: 1,
    buy: false,
    video: video9,
    song: 'Lost Children',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 10,
    noteID: 207,
    songprice: 1.5,
    buy: false,
    video: video10,
    song: 'Ragana',
    picture: pictureLtEng,
    album: 'Witch',
    albumprice: 15,
  },
  {
    id: 11,
    noteID: 208,
    songprice: 1.5,
    buy: false,
    video: video11,
    song: 'Dwarfs',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 12,
    noteID: 209,
    songprice: 1.5,
    buy: false,
    video: video12,
    song: 'Fairy',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 13,
    noteID: 210,
    songprice: 2,
    buy: false,
    video: video13,
    song: 'Wizard',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 14,
    noteID: 211,
    songprice: 1.5,
    buy: false,
    video: video14,
    song: 'Crow',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 15,
    noteID: 212,
    songprice: 2,
    buy: false,
    video: video15,
    song: 'Fox',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 16,
    noteID: 213,
    songprice: 1.5,
    buy: false,
    video: video16,
    song: 'Caterpillar - Butterfly',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 17,
    noteID: 214,
    songprice: 2,
    buy: false,
    video: video17,
    song: 'Chickens And Cocks',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 18,
    noteID: 215,
    songprice: 1.5,
    buy: false,
    video: video18,
    song: 'Cat',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 19,
    noteID: 216,
    songprice: 2,
    buy: false,
    video: video19,
    song: 'Seagull',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 20,
    noteID: 217,
    songprice: 1.5,
    buy: false,
    video: video20,
    song: 'Waves',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 21,
    noteID: 218,
    songprice: 1.5,
    buy: false,
    video: video21,
    song: 'Swan',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 22,
    noteID: 219,
    songprice: 2,
    buy: false,
    video: null,
    song: 'Horses II',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 23,
    noteID: 220,
    songprice: 2,
    buy: false,
    video: video23,
    song: 'Flowers',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 24,
    noteID: 221,
    songprice: 2,
    buy: false,
    video: video24,
    song: 'Air Balloon',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 25,
    noteID: 222,
    songprice: 2,
    buy: false,
    video: video25,
    song: 'Spring',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 26,
    noteID: 223,
    songprice: 1.5,
    buy: false,
    video: video26,
    song: 'Summer By the sea',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 27,
    noteID: 224,
    songprice: 1.5,
    buy: false,
    video: video27,
    song: 'Autumn Wind And Leaves',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 28,
    noteID: 225,
    songprice: 1.5,
    buy: false,
    video: video28,
    song: 'Winter From the Hill',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
  {
    id: 29,
    noteID: 226,
    songprice: 2.5,
    buy: false,
    video: video29,
    song: 'Waves II',
    picture: pictureLtEng,
    album: 'Musical Fairy Tale',
    albumprice: 15,
  },
];
export const muzikinePasakaRu = [
  {
    id: 1,
    noteID: 227,
    songprice: 1.5,
    buy: false,
    video: video1,
    song: 'Сказочная страна',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
    holealbumsold: false,
  },
  {
    id: 2,
    noteID: 228,
    songprice: 1,
    buy: false,
    video: video2,
    song: 'Волк',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 3,
    noteID: 229,
    songprice: 1,
    buy: false,
    video: video3,
    song: 'Красная Шапочка',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 4,
    noteID: 230,
    songprice: 1.5,
    buy: false,
    video: video4,
    song: 'Медведь',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 5,
    noteID: 231,
    songprice: 2,
    buy: false,
    video: video5,
    song: 'Пчелы',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 6,
    noteID: 232,
    songprice: 1.5,
    buy: false,
    video: video6,
    song: 'Винни - Пух',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 7,
    noteID: 233,
    songprice: 1,
    buy: false,
    video: video7,
    song: 'Грустный ослик',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 8,
    noteID: 234,
    songprice: 1,
    buy: false,
    video: video8,
    song: 'Мудрая сова',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 9,
    noteID: 235,
    songprice: 1,
    buy: false,
    video: video9,
    song: 'Заблудившиеся дети',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 10,
    noteID: 236,
    songprice: 1.5,
    buy: false,
    video: video10,
    song: 'Баба Яга',
    picture: pictureRu,
    album: 'Witch',
    albumprice: 15,
  },
  {
    id: 11,
    noteID: 237,
    songprice: 1.5,
    buy: false,
    video: video11,
    song: 'Гномы',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 12,
    noteID: 238,
    songprice: 1.5,
    buy: false,
    video: video12,
    song: 'Фея',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 13,
    noteID: 239,
    songprice: 2,
    buy: false,
    video: video13,
    song: 'Колдун',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 14,
    noteID: 240,
    songprice: 1.5,
    buy: false,
    video: video14,
    song: 'ворона',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 15,
    noteID: 241,
    songprice: 2,
    buy: false,
    video: video15,
    song: 'Лиса',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 16,
    noteID: 242,
    songprice: 1.5,
    buy: false,
    video: video16,
    song: 'Гусеница - Бабочка',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 17,
    noteID: 243,
    songprice: 2,
    buy: false,
    video: video17,
    song: 'Курицы и петухи',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 18,
    noteID: 244,
    songprice: 1.5,
    buy: false,
    video: video18,
    song: 'Кошка',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 19,
    noteID: 245,
    songprice: 2,
    buy: false,
    video: video19,
    song: 'Чайка',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 20,
    noteID: 246,
    songprice: 1.5,
    buy: false,
    video: video20,
    song: 'Волны',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 21,
    noteID: 247,
    songprice: 1.5,
    buy: false,
    video: video21,
    song: 'Лебедь',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 22,
    noteID: 248,
    songprice: 2,
    buy: false,
    video: null,
    song: 'Кони',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 23,
    noteID: 249,
    songprice: 2,
    buy: false,
    video: video23,
    song: 'Цветы',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 24,
    noteID: 250,
    songprice: 2,
    buy: false,
    video: video24,
    song: 'Воздушный шарик',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 25,
    noteID: 251,
    songprice: 2,
    buy: false,
    video: video25,
    song: 'Весна. Капель',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 26,
    noteID: 252,
    songprice: 1.5,
    buy: false,
    video: video26,
    song: 'Лето. У моря',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 27,
    noteID: 253,
    songprice: 1.5,
    buy: false,
    video: video27,
    song: 'Осень. Листопад',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 28,
    noteID: 254,
    songprice: 1.5,
    buy: false,
    video: video28,
    song: 'Зима. С Горки',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
  {
    id: 29,
    noteID: 255,
    songprice: 2.5,
    buy: false,
    video: video29,
    song: 'Волны II',
    picture: pictureRu,
    album: 'Музыкальные сказки',
    albumprice: 15,
  },
];
