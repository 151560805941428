import React from 'react';

const RuFlag = ({width}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} id='flag-icon-css-ru' viewBox='0 0 512 512'>
      <g fillRule='evenodd' strokeWidth='1pt'>
        <path fill='#fff' d='M0 0h512v512H0z' />
        <path fill='#0039a6' d='M0 170.7h512V512H0z' />
        <path fill='#d52b1e' d='M0 341.3h512V512H0z' />
      </g>
    </svg>
  );
};

export default RuFlag;
